import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from 'components/pages/home/Home';
import ProudlySupportedBy from 'components/pages/ProudlySupportedBy/ProudlySupportedBy';
import './App.css';

function App() {
  return (
    <div data-testid="app" className="App">
      <Switch>
        <Route exact path="/">
          <Redirect to="/en" />
        </Route>
        <Route exact path="/supported-by">
          <Redirect to="/en/supported-by" />
        </Route>
        <Route path="/:language" exact>
          <Home />
        </Route>
        <Route path="/:language/supported-by">
          <ProudlySupportedBy />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
