import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import DebtClock from 'components/common/DebtClock';
import SlidingAds from 'components/pages/home/SlidingAds';
import strings from 'config/strings';
import {
  calculateFederalStartValueAndIncrementRate,
  calculateShareStartValueAndIncrementRate,
  getConfig,
  selectLogo,
} from 'support/utils';
import { UPDATE_INTERVAL } from 'config/constants';
import './Home.css';

function Home() {
  const [debtConfigState, setDebtConfigState] = useState({
    debtFederal: null,
    incrementRateFederal: null,
    debtShare: null,
    incrementRateShare: null,
    population: null,
    startDebt: null,
    endDebt: null,
    startDate: null,
    endDate: null,
    ads: null,
  });

  const federalInterval = 12000;
  const shareInterval = 1000000;

  async function setDebtConfig() {
    const debtConfig = await getConfig();
    const { startDebt, endDebt, startDate, endDate, population, ads } =
      debtConfig;
    const debtConfigFederal = calculateFederalStartValueAndIncrementRate(
      startDebt,
      endDebt,
      startDate,
      endDate,
      federalInterval,
      population,
    );
    const debtConfigShare = calculateShareStartValueAndIncrementRate(
      startDebt,
      endDebt,
      startDate,
      endDate,
      shareInterval,
      population,
    );
    setDebtConfigState({
      ...debtConfigState,
      debtFederal: debtConfigFederal.startValue,
      incrementRateFederal: debtConfigFederal.incrementRate,
      debtShare: debtConfigShare.startValue,
      incrementRateShare: debtConfigShare.incrementRate,
      population,
      startDebt,
      endDebt,
      startDate,
      endDate,
      ads,
    });
  }

  useEffect(() => {
    async function checkIfConfigUpdated() {
      const config = await getConfig();
      const { startDebt, endDebt, startDate, endDate, population, ads } =
        debtConfigState;
      const currentConfig = {
        population,
        startDebt,
        endDebt,
        startDate,
        endDate,
        ads,
      };
      const fetchedConfig = {
        population: config.population,
        startDebt: config.startDebt,
        endDebt: config.endDebt,
        startDate: config.startDate,
        endDate: config.endDate,
        ads: config.ads,
      };
      const updated = !equal(currentConfig, fetchedConfig);
      if (updated) {
        setDebtConfig();
      }
    }
    const updateConfig = setInterval(() => {
      checkIfConfigUpdated();
    }, UPDATE_INTERVAL);
    return () => {
      clearInterval(updateConfig);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtConfigState]);

  useEffect(() => {
    if (
      !debtConfigState.population &&
      !debtConfigState.startDebt &&
      !debtConfigState.endDebt &&
      !debtConfigState.startDate &&
      !debtConfigState.endDate &&
      !debtConfigState.debtFederal &&
      !debtConfigState.debtShare &&
      !debtConfigState.incrementRateFederal &&
      !debtConfigState.incrementRateShare &&
      !debtConfigState.ads
    ) {
      setDebtConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtConfigState]);
  const { language } = useParams();
  strings.setLanguage(language);
  return (
    <React.Fragment>
      <div data-testid="debt-clock" className="main-container">
        <div className="debt-clock-federal-container">
          <h1
            data-testid="debt-clock-federal-text"
            className={classNames(
              'debt-clock-federal-text',
              `debt-clock-federal-text-${language}`,
            )}
          >
            {strings.debtClock.debtClockFederal.toUpperCase()}
          </h1>
          {debtConfigState.debtFederal &&
            debtConfigState.incrementRateFederal && (
              <DebtClock
                interval={federalInterval}
                duration={4000}
                initialDebt={debtConfigState.debtFederal}
                incrementRate={debtConfigState.incrementRateFederal}
              />
            )}
        </div>
        <div className="debt-clock-share-taxpayer-logo-container">
          <div className="taxpayer-logo">
            <img
              src={selectLogo(language)}
              alt="tax payer logo"
              className="logo-width"
            />
          </div>
          <div className="debt-clock-share">
            <h1
              data-testid="debt-clock-share-text"
              className={classNames(
                `debt-clock-share-text`,
                `debt-clock-share-text-${language}`,
              )}
            >
              {strings.debtClock.debtClockShare.toUpperCase()}
            </h1>
            {debtConfigState.debtShare &&
              debtConfigState.incrementRateShare && (
                <DebtClock
                  interval={shareInterval}
                  duration={4000}
                  initialDebt={debtConfigState.debtShare}
                  incrementRate={debtConfigState.incrementRateShare}
                />
              )}
          </div>
        </div>
        <div className="sign-petition-text-container">
          <span
            data-testid="sign-petition-text"
            className="sign-petition-text "
          >
            {strings.debtClock.signPetitionNotBold}
            <span className="sign-petition-bold">
              {strings.debtClock.signPetitionBold}
            </span>
          </span>
        </div>
      </div>
      <div className="supported-by-container">
        <h1
          data-testid="supported-by-text"
          className={classNames(
            'supported-by-text',
            `supported-by-text-${language}`,
          )}
        >
          {strings.debtClock.supportedBy.toUpperCase()}
        </h1>
        {debtConfigState.ads && <SlidingAds ads={debtConfigState.ads} />}
      </div>
    </React.Fragment>
  );
}

export default Home;
