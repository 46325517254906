import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DebtClock.css';
import Odometer from 'react-odometerjs';

function DebtClock({ initialDebt, incrementRate, duration, interval }) {
  const [startDebt, setStartDebt] = useState(null);
  const [currentDebt, setCurrentDebt] = useState(null);
  const [increment, setIncrement] = useState(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function reset() {
      setStartDebt(initialDebt);
      setCurrentDebt(initialDebt);
      setIncrement(incrementRate);
    }
    if (!currentDebt && !increment && !startDebt) {
      reset();
    }
    if (currentDebt && increment && startDebt) {
      if (initialDebt !== startDebt) {
        reset();
      } else {
        const updateDebt = setInterval(() => {
          const newDebt = parseFloat(currentDebt + incrementRate).toFixed(2);
          if (newDebt.toString().charAt(newDebt.length - 1) === '0') {
            setCurrentDebt(currentDebt + incrementRate + 0.01);
          } else {
            setCurrentDebt(currentDebt + incrementRate);
          }
        }, interval);
        return () => {
          clearInterval(updateDebt);
        };
      }
    }
  }, [initialDebt, incrementRate, interval, increment, currentDebt, startDebt]);

  return (
    <React.Fragment>
      <span data-testid="debt-clock">
        {currentDebt && (
          <Odometer
            value={currentDebt.toString()}
            format="(,ddd).dd"
            theme="custom"
            duration={duration}
          />
        )}
      </span>
    </React.Fragment>
  );
}

DebtClock.propTypes = {
  initialDebt: PropTypes.number.isRequired,
  incrementRate: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
};

export default DebtClock;
