import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
  en: {
    debtClock: {
      debtClockFederal: `CANADA'S FEDERAL DEBT`,
      debtClockShare: 'YOUR SHARE',
      signPetitionNotBold: 'Sign the petition at ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'PROUDLY SUPPORTED BY',
    },
    proudlySupportedBy: {
      supportedBy: `CANADA'S NATIONAL DEBT CLOCK PROUDLY SUPPORTED BY`,
    },
  },
  fr: {
    debtClock: {
      debtClockFederal: 'DETTE FÉDÉRALE DU CANADA',
      debtClockShare: 'VOTRE PART',
      signPetitionNotBold: 'Signez la pétition au ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'FIÈREMENT SOUTENU PAR',
    },
    proudlySupportedBy: {
      // TODO: Change once translation received
      supportedBy: `L'HORLOGE NATIONALE DE LA DETTE DU CANADA FIÈREMENT APPUYÉE PAR`,
    },
  },
  cn: {
    debtClock: {
      // Mandarin
      debtClockFederal: `加拿大联邦债务`,
      debtClockShare: '您的份额',
      signPetitionNotBold: '在请愿书上签名 ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: '支持源自',
    },
    proudlySupportedBy: {
      supportedBy: `加拿大国债钟支持源自`,
    },
  },
  cn_yue: {
    debtClock: {
      // Cantonese
      debtClockFederal: `加拿大聯邦債務`,
      debtClockShare: '你的份額',
      signPetitionNotBold: '在請願書上簽署 ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: '支持源自',
    },
    proudlySupportedBy: {
      supportedBy: `加拿大國債鐘支持源自`,
    },
  },
  tl: {
    debtClock: {
      // Tagalog/Filipino
      debtClockFederal: `Utang Pederal ng Canada`,
      debtClockShare: 'Ang pamamahagi mo',
      signPetitionNotBold: 'Pirmahan ang petisyon sa ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'Marangal na sinusuporta ng',
    },
    proudlySupportedBy: {
      supportedBy: `Orasan ng Utang Pederal ng Canada ay Marangal na Sinusuporta ng`,
    },
  },
  pa: {
    debtClock: {
      // Punjabi
      debtClockFederal: `ਕੈਨੇਡਾ ਦਾ ਫੈਡਰਲ ਕਰਜ਼ਾ`,
      debtClockShare: 'ਤੁਹਾਡਾ ਹਿੱਸਾ',
      signPetitionNotBold: 'ਪਟੀਸ਼ਨ ਉੱਤੇ ਇੱਥੇ ਦਸਤਖਤ ਕਰੋ ',
      signPetitionBold: 'www.DebtClock.ca',
      supportedBy: 'ਮਾਣ ਨਾਲ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ',
    },
    proudlySupportedBy: {
      supportedBy: `ਕੈਨੇਡਾ ਦੀ ਰਾਸ਼ਟਰੀ ਕਰਜ਼ਾ ਘੜੀ (Canada's National Debt Clock) ਨੂੰ ਇਸ ਤੋਂ ਮਾਣ ਨਾਲ ਸਹਿਯੋਗ ਪ੍ਰਾਪਤ ਹੈ`,
    },
  },
});

export default strings;
