const backup = {
  startDebt: 1079000000000,
  endDebt: 2000000000000,
  population: 40000000,
  startDate: '04/01/2021',
  endDate: '03/31/2022',
  ads: [
    'Some Very Longname 123',
    'Some Very Longname 123',
    'Company Name',
    'Firstname Lastname',
  ],
  supportedByImages: [],
};

export default backup;
